import React from "react";
import pureIcons from "../../shared/pure_icons";
import { saveFilterSelection } from "../../shared/filter_selection";
import { FormControl, Badge, Select, MenuItem, FormControlLabel, Radio } from "@material-ui/core";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import Moment from "moment";
import { handleReviewStatus, handleReturnToPreviousStage } from "./api";
const TABLE_COLUMNS = (component) => [
  {
    title: "Invoice",
    field: "invoice",
    sorting: false,
    filtering: false,
    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 400,
    },
  },
  {
    title: "Order Number",
    field: "order_number",
    sorting: false,
    filtering: false,
    editable: "never",
    render: (rowData) => (
      <Badge
        badgeContent={rowData.new_customer ? "new" : null}
        color="secondary"
      >
        {rowData.order_number}
      </Badge>
    ),
  },
  { title: "Vendor", field: "vendor", sorting: false, editable: "never", filtering: false },
  {
    title: "Items",
    field: "products_count",
    sorting: false,
    filtering: false,
    editable: "never",
    cellStyle: { width: "2%" },
  },
  { title: "invoice Total", field: "total", sorting: false, filtering: false },
  {
    title: "Shipping Method",
    field: "shipping_method",
    sorting: false,
    editable: "never",
    filterComponent: () => (
      <FormControl component="fieldset" style={{ width: "90%" }}>
        <Select
          value={component.shippingOption.current}
          onChange={(e) => {
            component.handleRoleFilterChange(e.target.value),
              (component.shippingOption.current = e.target.value);
            saveFilterSelection("hub-selected-filter", e.target.value);
          }}
        >
        {component.state.shippingMethods.map((item) => {
          return (
            <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
              <FormControlLabel
                value={item}
                control={
                  <Radio checked={component.shippingOption.current == item} />
                }
                label={item}
              />
            </MenuItem>
          );
        })}
        </Select>
      </FormControl>
    ),
    render: (rowData) => <span>{rowData?.shipping_option}</span>,
  },
  {
    title: "ETA",
    field: "eta",
    type: "date",
    filtering: false,
    dateSetting: { format: "dd/MM/yyyy" },
    render: (rowData) => {
      return rowData.flag ? (
        <div style={{ color: "#FF0000" }}>
          {Moment(rowData.eta).format("DD/MM/YYYY")}
        </div>
      ) : (
        <div>{Moment(rowData.eta).format("DD/MM/YYYY")}</div>
      );
    },
  },
  { title: "Time purchased", field: "created_at", editable: "never", filtering: false },
  { title: "id", field: "id", hidden: true },
  { title: "group_id", field: "group_id", hidden: true },
  { title: "market_source", field: "market_source", hidden: true },
  { title: "source", field: "source", hidden: true },
  { title: "new_customer", field: "new_customer", hidden: true },
];

const TABLE_ACTIONS = (component) => [
  {
    icon: () => <pureIcons.Traffic />,
    onClick: () => {
      component._AutoControlPanelRef.current.handleOpen();
    },
    isFreeAction: true,
    tooltip: "Auto control",
  },
  {
    icon: () => <pureIcons.FiRefreshCw />,
    onClick: () => {
      component.reloadData();
    },
    isFreeAction: true,
    tooltip: "Refresh",
  },
  {
    icon: () => <pureIcons.FaShippingFast />,
    tooltip: <span>Ship</span>,
    onClick: (event, rowData) =>
      component._ShippingFormRef.current.handleOpen(
        rowData.raw_invoice,
        rowData.order_number,
        rowData.vendor,
        rowData.id,
        rowData.destination,
        rowData.group_id,
        rowData.source,
        rowData.new_customer,
        rowData.shipping_option
      )
    ,
    position: "row",
  },

  (rowData) => ({
    icon: () => <pureIcons.MdPreview />,
    tooltip:
      rowData.is_reviewed === true ? (
        <span>Reviewed By: {rowData.reviewer}</span>
      ) : (
        <span>Mark as Reviewed</span>
      ),
    onClick: (event, rowData) => {
      window.confirm(
        `Are you sure you want to change status to be { REVIEWED } ?`
      ) && handleReviewStatus(rowData, component);
    },
    position: "row",
    disabled: rowData.is_reviewed === true,
  }),
  {
    icon: () => <AssignmentReturnIcon />,
    tooltip: "Return to previous stage",
    onClick: (event, rowData) => {
      window.confirm(
        `Are you sure you want to return ${rowData.invoice} to previous stage?`
      ) && handleReturnToPreviousStage(rowData, component);
    },
    position: "row",
  }
];

const CONFIG = {
  TABLE_COLUMNS,
  TABLE_ACTIONS,
};

export default CONFIG;
