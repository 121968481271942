import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import App from "../app";
import { SnackbarProvider } from "notistack";

const Session = () => {
  const idleTimerRef = useRef(null);

  const checkUserIfActive = () => {
    const url = `/core_users/idle?location=${window.location.href}`;
    fetch(url)
      .then((response) => {
        if (response.status === 401 && !url.includes("dummy_packages")) {
          location.href = "/";
          history.pushState(null, null, location.href);
          window.onpopstate = function () {
            history.go(1);
          };
        } else {
          idleTimerRef.current?.reset();
        }
      })
      .catch(() => {
        location.href = "/";
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
          history.go(1);
        };
      });
  };

  const handleAction = () => {
    checkUserIfActive();
  };

  const handleIdle = () => {
    checkUserIfActive();
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        onIdle={handleIdle}
        stopOnIdle={false}
        eventsThrottle={1000 * 60 * 5}
        onAction={handleAction}
        timeout={1000 * 60 * 30}
      />
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </>
  );
};

export default Session;
