import React from "react";
import Modal from "../../shared/modal/material_dialog";
import { withSnackbar } from "notistack";
import $ from "jquery";
import Backdrop from "../../shared/backdrop";
import Table from "../../shared/tables/new_core_material_table";
import pureIcons from "../../shared/pure_icons";
import ReturnFrom from "./_return_form"

class ProcessFrom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      working: false,
    };
    this._tableRef = React.createRef();
    this._returnFromRef = React.createRef();
  }

  handleOpen = (data) => {
    this.setState({
      open: true,
      order_number: data.order_number,
      tracking: data.raw_tracking,
      destination: data.destination,
      source: data.source,
    });
    this.getProducts(data.raw_tracking, data.destination, this.props.orderState);
  };

  handleClose = () => {
    this.setState({ open: false });
    const searchBar = document.querySelector(`#pure_table_toolbar_id input`);
    if (searchBar) searchBar.focus();
  };

  getProducts = (tracking, destination, orderState) => {
    this.setState({ working: true });
    const url = `/order_item_stages/exceptions_to_process.json?tracking=${tracking}&destination=${destination}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        result.stage_units.length === 0
          ? this.handleClose()
          : this.setState({
              stage_units: result.stage_units,
              working: false
            });
      })
      .catch((error) => {
        this.props.enqueueSnackbar(
          "Invalid tracking or items has been moved to the next stage!",
          { variant: "error" }
        );
        this.handleClose();
      });
  };

  handleShip = (data) => {
    console.log(data);
  }

  handleDispose = (data) => {
    let self = this;
    this.setState({ working: true });
    const formData = new FormData();
    formData.append('action_name', 'dispose');
    formData.append('tracking', this.state.tracking);
    formData.append('destination', this.state.destination);
    formData.append('shipping_option', this.props.shippingMethod);
    formData.append('data', JSON.stringify(data));
    $.ajax({
      url: `/order_item_stages/process_exceptions`,
      type: "POST",
      data: formData,
      dataType: "json",
      processData: false,
      contentType: false,
      success: function (response) {
        self.setState({ working: false, open: false });
        self.props.enqueueSnackbar("successfully processed!", {
          variant: "success",
        });
        self.props.reloadData();
      },
      error: function (response) {
        self.props.enqueueSnackbar(response.responseJSON.error, {
          variant: "error",
        });
        self.setState({ working: false });
      },
    });
  }

  reloadData = () => {
    this.props.reloadData();
    this.handleClose();
  }

  render() {
    const columns = [
      {
        title: "Product Name",
        field: "name",
        defaultGroupOrder: 0
      },
      {
        title: "KSU Number",
        field: "sku",
        grouping: false,
        cellStyle: { width: '10%' }
      },
      {
        title: "Issues",
        field: "issues",
        grouping: false,
        cellStyle: { width: '90%' }
      },
      {
        title: "id",
        field: "id",
        hidden: true,
        grouping: false
      }
    ]
    const body =
      <div className="bg-white rounded">
        <Backdrop open={this.state.working}/>
        <ReturnFrom ref={this._returnFromRef} reloadData={this.reloadData} destination={this.state.destination} shipping_option={this.props.shippingMethod} source={this.state.source}/>
        <Table
          title={""}
          options={{
            debounceInterval: 1000,
            searchAutoFocus: true,
            filtering: false,
            selection: true,
            focus: true
          }}
          columns={columns}
          tableRef={this._tableRef}
          data={this.state?.stage_units}
          actions={[
            {
              icon: () => (<pureIcons.Delete />),
              tooltip: "Dispose",
              onClick: (event, data) => this.handleDispose(data),
            },
            {
              icon: () => (<pureIcons.TruckReturn />),
              tooltip: "Return",
              onClick: (event, data) => this._returnFromRef.current.handleOpen(data, this.state.tracking),
            },
            {
              icon: () => (<pureIcons.FaShippingFast />),
              tooltip: "Ship",
              disabled: true,
              onClick: (event, data) => this.handleShip(data),
            },
          ]}
        />
      </div>

    return (
      <Modal
        disableBackdropClick={false}
        isOpen={this.state.open}
        title={this.state?.order_number}
        handleClose={this.handleClose}
        minHeight="40%"
        maxWidth="lg"
        maxHeight="85%"
        actions={<></>}
      >
        {body}
      </Modal>
    );
  }
}

export default withSnackbar(ProcessFrom);
