import React, { useEffect, useState, createRef } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FiShoppingCart,
  FiChevronsLeft,
  FiChevronsRight,
  FiSearch,
  FiPackage,
} from "react-icons/fi";
import {
  FaClipboardList,
  FaShippingFast,
  FaWarehouse,
  FaFileInvoiceDollar,
  FaBusinessTime,
  FaRegThumbsUp,
} from "react-icons/fa";
import { AiOutlineFileSearch } from "react-icons/ai";
import {
  GiBoxUnpacking,
  GiStorkDelivery,
  GiCommercialAirplane,
  GiCardPickup,
} from "react-icons/gi";
import { MdOutlineProductionQuantityLimits, MdWarningAmber } from "react-icons/md";
import { BsHandThumbsUp, BsBookshelf } from "react-icons/bs";
import { IoBarChartSharp } from "react-icons/io5";
import {
  GrDocumentCsv,
  GrSettingsOption,
  GrUserSettings,
} from "react-icons/gr";
import FindItem from "../marketplace_products/_index";
import SourceDestinationSelection from "../shared/_app_settings";
import Flags from "country-flag-icons/react/3x2";
import InfoIcon from "@material-ui/icons/Info";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import { CiInboxOut, CiInboxIn } from "react-icons/ci";
import Global_Flag from "../../../assets/images/locations/global.png";

const Sidebar = ({ page_id }) => {
  const [sidebar, setSidebar] = useState([]);
  const [collapsed, setCollapsed] = useState(localStorage.getItem("collapsed") === "true");

  const _FindItemRef = createRef();
  const _OpenSourceDestinationModel = createRef();

  useEffect(() => {
    const url = page_id ? `/sidebar_content.json?page_id=${page_id}` : `/sidebar_content.json`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setSidebar(data.sidebar || []);
        localStorage.setItem("sidebarLength", data.sidebar.length || 0);
      });
  }, [page_id]);

  const toggleSidebar = () => {
    const newState = !collapsed;
    setCollapsed(newState);
    document.getElementById("content")?.setAttribute("collapsed", newState);
    localStorage.setItem("collapsed", newState.toString());
  };

  const iconMap = {
    FiShoppingCart,
    FaClipboardList,
    FiSearch,
    AiOutlineFileSearch,
    GiBoxUnpacking,
    FaShippingFast,
    FaWarehouse,
    BsHandThumbsUp,
    BsBookshelf,
    FiPackage,
    IoBarChartSharp,
    GrDocumentCsv,
    GrSettingsOption,
    GrUserSettings,
    FaFileInvoiceDollar,
    FaBusinessTime,
    GiStorkDelivery,
    FaRegThumbsUp,
    GiCommercialAirplane,
    GiCardPickup,
    MdOutlineProductionQuantityLimits,
    MdWarningAmber,
    ImportExportIcon,
    CiInboxIn,
    CiInboxOut,
  };

  const renderFlag = (code) => {
    const FlagComponent = Flags[code];
    return FlagComponent ? <FlagComponent className="flag-style" style={{ width: 30, height: 30 }} /> : <img src={global_image} />;
  };

  const content = new Uint8Array(Global_Flag);
  const global_image = URL.createObjectURL(
    new Blob([content.buffer], { type: "image/png" } /* (1) */)
  );

  const source = localStorage.getItem("source")?.toUpperCase() || "N/A";
  const destination = localStorage.getItem("destination")?.toUpperCase() || "N/A";

  return (
    sidebar.length > 0 && (
      <ProSidebar collapsed={collapsed} breakPoint="lg">
        <FindItem ref={_FindItemRef} />
        <SidebarHeader>
          <div className="block" style={{ float: "right" }}>
            <IconButton size="small" onClick={toggleSidebar}>
              {collapsed ? <FiChevronsRight /> : <FiChevronsLeft />}
            </IconButton>
          </div>
          {!collapsed && (
            <div style={{ padding: "10px 10px", fontSize: "15px" }}>
              <label onClick={() => _OpenSourceDestinationModel.current?.handleOpen()}>
                Source: &nbsp; {renderFlag(source)} {source}
              </label>
              <label onClick={() => _OpenSourceDestinationModel.current?.handleOpen()}>
                Destination: &nbsp; {renderFlag(destination)} {destination}
              </label>
            </div>
          )}
        </SidebarHeader>
        <SidebarContent>
          <SourceDestinationSelection ref={_OpenSourceDestinationModel} />
          <Menu iconShape="circle">
            <MenuItem
              icon={<FiSearch />}
              onClick={() => _FindItemRef.current?.handleOpen()}
            >
              Find Item
            </MenuItem>
            {sidebar.map((item) =>
              item.menu ? (
                <MenuItem
                  key={item.menu.name}
                  icon={React.createElement(iconMap[item.menu.icon])}
                >
                  {item.menu.name}
                  <Link to={item.menu.link} />
                </MenuItem>
              ) : (
                <SubMenu
                  key={item.sub_menu.name}
                  title={item.sub_menu.name}
                  icon={React.createElement(iconMap[item.sub_menu.icon])}
                  defaultOpen={item.sub_menu.open}
                >
                  {item.sub_menu.sub_menu_items.map((subItem) =>
                    subItem.sub_menu ? (
                      <SubMenu
                        key={subItem.sub_menu.name}
                        title={subItem.sub_menu.name}
                        icon={React.createElement(iconMap[subItem.sub_menu.icon])}
                        defaultOpen={subItem.sub_menu.open}
                      >
                        {subItem.sub_menu.sub_menu_items.map((nestedItem) => (
                          <MenuItem
                            key={nestedItem.name}
                            icon={React.createElement(iconMap[nestedItem.icon])}
                          >
                            {nestedItem.name}
                            <Link to={nestedItem.link} />
                          </MenuItem>
                        ))}
                      </SubMenu>
                    ) : (
                      <MenuItem
                        key={subItem.name}
                        icon={React.createElement(iconMap[subItem.icon])}
                      >
                        {subItem.name}
                        <Link to={subItem.link} />
                      </MenuItem>
                    )
                  )}
                </SubMenu>
              )
            )}
            <MenuItem icon={<InfoIcon />}>
              Dummy Packages
              <Link to="/dummy_packages" />
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter />
      </ProSidebar>
    )
  );
};

export default Sidebar;
