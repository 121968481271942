import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
  Button,
  Card,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "../../shared/backdrop";
import {DEFAULT_HEADERS} from "./api"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    margin: theme.spacing(1),
    width: "50ch",
  },
  textField2: {
    margin: theme.spacing(1),
    width: "22ch",
  },
  button: {},
}));

const ShippingForm = forwardRef(({ reloadData, source, destination, activeCarriers }, ref) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [single, setSingle] = useState("yes");
  const [showBox, setShowBox] = useState("no");
  const [carrier, setCarrier] = useState("");
  const [eta, setEta] = useState(null);

  useImperativeHandle(ref, () => ({
    handleOpen(data) {
      const initialValues = { master_waybill: "" };
      data?.forEach((box) => {
        initialValues[box.group_id] = "";
      });
      const estimatedDate = new Date();
      estimatedDate.setDate(estimatedDate.getDate() + 21); // 3 weeks
      setOpen(true);
      setData(data);
      setValues(initialValues);
      setErrors({});
      setCarrier("");
      setEta(estimatedDate);
    },
  }));

  const handleClose = () => setOpen(false);

  const validate = (fieldValues = values) => {
    const temp = { ...errors };

    if ("master_waybill" in fieldValues) {
      temp.master_waybill = fieldValues.master_waybill ? "" : "Required";
    }
    if ("carrier" in fieldValues) {
      temp.carrier = fieldValues.carrier ? "" : "Required";
    }
    data?.forEach((box) => {
      if (showBox === "yes" && box.group_id in fieldValues) {
        temp[box.group_id] = fieldValues[box.group_id] ? "" : "Required";
      }
    });

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    validate({ [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return;

    setSubmitting(true);

    try {
      const response = await fetch(
        `/order_item_stages/add_shipper_tracking_number.json?source=${source}&destination=${destination}`,
        {
          method: "POST",
          headers: DEFAULT_HEADERS,
          body: JSON.stringify({ values, data, carrier, single, showBox }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Something went wrong!");
      }

      enqueueSnackbar("Successfully processed!", { variant: "success" });
      reloadData();
      handleClose();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setSingle(value);
    if (value === "no") {
      setShowBox("yes");
    }
  };

  const handleRadio2Change = (e) => {
    const { value } = e.target;
    setShowBox(value);
    if (value === "no") {
      setValues((prevValues) => ({
        master_waybill: prevValues.master_waybill,
      }));
    }
  };

  const body = (
    <Card className={classes.root} style={{ minHeight: "30vh" }}>
      <div className="container pb-5">
        <div className="row pt-2 ps-1">
          <Select
            name="carrier"
            className={classes.textField2}
            displayEmpty
            value={carrier}
            onChange={(event) => setCarrier(event.target.value)}
            error={!!errors.carrier}
          >
            <MenuItem value="" disabled>
              Select carrier
            </MenuItem>
            {activeCarriers?.map((carrierOption, index) => (
              <MenuItem value={carrierOption} key={index}>
                {carrierOption}
              </MenuItem>
            ))}
          </Select>
        </div>
        {carrier && (
          <>
            <RadioGroup
              aria-label="single-waybill"
              name="single-waybill"
              value={single}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Single waybill" />
              <FormControlLabel value="no" control={<Radio />} label="Multiple waybills" />
            </RadioGroup>
            <div className="row pt-2">
              <TextField
                fullWidth
                className={classes.textField2}
                name="master_waybill"
                value={values.master_waybill}
                label={
                  single === "yes" ? "Waybill number" : "Group number/ Master waybill"
                }
                error={!!errors.master_waybill}
                helperText={errors.master_waybill}
                onChange={handleInputChange}
              />
            </div>
            <RadioGroup
              aria-label="show-boxes"
              name="show-boxes"
              value={showBox}
              onChange={handleRadio2Change}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Box number" />
              <FormControlLabel value="no" control={<Radio />} label="No box number" />
            </RadioGroup>
            {showBox === "yes" &&
              data.map((box) => (
                <div className="row pt-2" key={box.group_id}>
                  <TextField
                    fullWidth
                    className={classes.textField}
                    name={box.group_id}
                    value={values[box.group_id]}
                    label={box.group_id}
                    error={!!errors[box.group_id]}
                    helperText={errors[box.group_id]}
                    onChange={handleInputChange}
                  />
                </div>
              ))}
          </>
        )}
      </div>
    </Card>
  );

  const actions = (
    <Button
      variant="contained"
      style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
      onClick={handleSubmit}
      disabled={submitting}
    >
      {submitting ? "Submitting..." : "Submit"}
    </Button>
  );

  return (
    <>
      <Backdrop open={submitting} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title="Carrier Tracking"
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="sm"
        actions={actions}
      >
        {body}
      </Modal>
    </>
  );
});

export default ShippingForm;
