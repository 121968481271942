// Fetch shipment data
export const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const fetchShipments = async (shippingOption, source, destination) => {
  const url = `/order_item_stages/ready_to_ship.json?shipping_option=${shippingOption}&source=${source}&destination=${destination}`;
  const response = await fetch(url, {
    method: "GET",
    headers: DEFAULT_HEADERS,
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || "Failed to fetch shipments");
  }

  return response.json();
};

export const updateStage = async (payload) => {
  const response = await fetch(`/order_item_stages/update_stage`, {
    method: "PATCH",
    headers: DEFAULT_HEADERS,
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error || "Failed to update stage");
  }

  return response.json();
};

export const handleUpdate = async (newValue, data, columnDef, destination, component) => {
  if (newValue === "" || newValue === null) return;

  let payload, url;

  switch (columnDef.field) {
    case "weight":
      url = `/order_item_stages/update_stage_tracking.json?destination=${destination}`;
      payload = {
        tracking: data.tracking,
        new_tracking: `${data.group_id}*${newValue}*${data.dimensions}`,
        stage: "ready_to_ship",
      };
      break;
    case "dimensions":
      url = `/order_item_stages/update_stage_tracking.json?destination=${destination}`;
      payload = {
        tracking: data.tracking,
        new_tracking: `${data.group_id}*${data.weight}*${newValue}`,
        stage: "ready_to_ship",
      };
      break;
    case "status":
      url = `/order_item_stages/update_stage.json?destination=${destination}`;
      payload = {
        status: newValue,
        group_id: data.group_id,
        stage: "ready_to_ship",
      };
      break;
    default:
      return;
  }

  try {
    component.setState({ submitting: true });

    const response = await fetch(url, {
      method: "PATCH",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "An error occurred");
    }

    await component.reloadData();
    component.props.enqueueSnackbar("Successfully processed!", {
      variant: "success",
    });
  } catch (error) {
    component.props.enqueueSnackbar(error.message, { variant: "error" });
  } finally {
    component.setState({ submitting: false });
  }
};
