import React from "react";
import Table from "../shared/tables/material_table";
import {MenuItem, Select} from '@material-ui/core';

export default  class ShowLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      muiTableKey: 0,
      actions: [],
      statuses: []
    };
    this._tableRef = React.createRef();
  }

  reloadData = () => { this._tableRef.current.onQueryChange();};

  render() {
    const columns = [
      {title: "Search_key", field: "search_key", sorting: true},
      {title: "SKU", field: "sku", sorting: false, cellStyle: { width: '30%' }},
      { title: "Action", field: "action", editable: "never", sorting: false,
        filterComponent: ({ columnDef, onFilterChanged }) => <Select
          name={columnDef.tableData.id}
          value={columnDef.tableData.action}
          onChange={(e) => {
            onFilterChanged(columnDef.tableData.id, e.target.value);
          }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {this.state.actions.map(v =>
            <MenuItem key={v} value={v}>{v}</MenuItem>
          )}
        </Select>
      },
      { title: "Status Code", field: "response_status", sorting: false,
        filterComponent: ({ columnDef, onFilterChanged }) => <Select
          name={columnDef.tableData.id}
          value={columnDef.tableData.response_status}
          onChange={(e) => {
            onFilterChanged(columnDef.tableData.id, e.target.value);
          }}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {this.state.statuses.map(v =>
            <MenuItem key={v} value={v}>{v}</MenuItem>
          )}
        </Select>
      },
      { title: "Date", field: "created_at", filtering: false}
    ];

    return (
      <div className="bg-white rounded">
        <Table
          title={this.props.title}
          key={this.state.muiTableKey}
          options={{
            search: false,
            actionsColumnIndex: -1,
            focus: true,
          }}
          localization={{
            toolbar: { searchPlaceholder: "Search key" },
          }}
          columns={columns}
          tableRef={this._tableRef}
          detailPanel={rowData => {
            return (
              <div className="container">
                <div className="row">
                  <p>{rowData.url}</p>
                </div>
                <div className="row">
                  <h5>Request</h5>
                  <p>{rowData.payload}</p>
                </div>
                <hr />
                <div className="row">
                  <h5>Response</h5>
                  <p>{rowData.response}</p>
                </div>
              </div>
            )
          }}
          data={(query) =>
            new Promise((resolve, reject) => {
              var filters;
              if (query.filters.length > 0) {
                filters = query.filters.map((col) => ({
                  field: col.column.field,
                  value: col.value,
                }));
              }
              let url = `/logs/${this.props.page}.json?&`;
              url += "per_page=" + query.pageSize;
              url += "&page=" + (query.page + 1);
              if (query.filters.length > 0) {
                url += "&filters=" + JSON.stringify(filters);
              }
              if (query.orderBy) {
                url += "&orderBy=" + query.orderBy.field;
                url += "&orderDirection=" + query.orderDirection;
              }
              if (query.search) {
                url += "&search=" + query.search;
              }
              fetch(url)
                .then((response) => response.json())
                .then((result) => {
                  resolve({
                    data: result.records,
                    page: result.page - 1,
                    totalCount: result.total,
                  });
                  if (this.state.actions.length === 0) {
                    this.setState({
                      actions: result.actions, statuses: result.statuses
                    });
                  }
                });
            })
          }
        />
      </div>
    );
  }
}
