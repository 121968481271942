import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  Typography,
  Select,
  Box,
  MenuItem,
  Paper,
  Button,
  FormHelperText
} from "@material-ui/core";
import Modal from "./modal/material_dialog";

const SourceDestinationSelection = forwardRef((props, ref) => {
  const source = [
    { id: "global", name: "GLOBAL" },
    { id: "us", name: "US" },
    { id: "tr", name: "TR" },
    { id: "ae", name: "AE" },
    { id: "sa", name: "SA" },
    { id: "iq", name: "IQ" },
    { id: "qa", name: "QA" },
    { id: "cn", name: "CN" },
  ];

  const destination = [
    { id: "iq", name: "IQ" },
    { id: "sa", name: "SA" },
    { id: "ae", name: "AE" },
    { id: "ng", name: "NG" },
  ];

  const [selectedSource, setSelectedSource] = useState(
    localStorage.getItem("source") || ""
  );
  const [selectedDestination, setSelectedDestination] = useState(
    localStorage.getItem("destination") || ""
  );
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    source: false,
    destination: false
  });

  useEffect(() => {
    if (selectedSource === "" || selectedDestination === "") setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  const handleOkClick = () => {
    if (selectedSource === "") {
      setError((prev) => ({ ...prev, source: true }));
    } else if (selectedDestination === "") {
      setError((prev) => ({ ...prev, destination: true }));
    } else {
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const modalActions = (
    <Button
      variant="contained"
      style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
      onClick={handleOkClick}
    >
      Ok
    </Button>
  );

  const selectStyles = { width: "100%" };

  return (
    <>
      <Modal
        disableBackdropClick={false}
        isOpen={open}
        title="Select source & destination"
        handleClose={() => {
          if (selectedSource === "") {
            setError((prev) => ({ ...prev, source: true }));
          } else if (selectedDestination === "") {
            setError((prev) => ({ ...prev, destination: true }));
          } else {
            handleClose();
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        }}
        maxHeight="60%"
        maxWidth="xs"
        actions={modalActions}
      >
        <Paper>
          <Box sx={{ p: "10px" }}>
            <Typography variant="subtitle1" color="textSecondary">
              Select source:
            </Typography>
            <Select
              name="select source"
              value={selectedSource}
              style={selectStyles}
              onChange={(ev) => {
                setSelectedSource(ev.target.value);
                localStorage.setItem("source", ev.target.value);
                setError((prev) => ({ ...prev, source: false }));
              }}
            >
              {source.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
            {error.source && (
              <FormHelperText style={{ color: "red" }}>
                Please select source
              </FormHelperText>
            )}

            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ marginTop: "5vh" }}
            >
              Select destination:
            </Typography>
            <Select
              name="select destination"
              value={selectedDestination}
              style={selectStyles}
              onChange={(ev) => {
                setSelectedDestination(ev.target.value);
                localStorage.setItem("destination", ev.target.value);
                setError((prev) => ({ ...prev, destination: false }));
              }}
            >
              {destination.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  {value.name}
                </MenuItem>
              ))}
            </Select>
            {error.destination && (
              <FormHelperText style={{ color: "red" }}>
                Please select destination
              </FormHelperText>
            )}
          </Box>
        </Paper>
      </Modal>
    </>
  );
});

export default SourceDestinationSelection;
