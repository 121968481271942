import React, { useEffect, useRef } from "react";
import Table from "../../shared/tables/new_core_material_table";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import HubProcessForm from "./_hub_process_form";
import {getFilterSelection} from "../../shared/filter_selection";
import CONFIG from "./tableConfig";
import {fetchData, handleUpdate} from "./api"
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      muiTableKey: 0,
      total: null,
      order_number: "",
      shippingMethods: [],
    };
    this._tableRef = React.createRef();
    this._HubProcessFormRef = React.createRef();
    this._DummyPackageRef = React.createRef();
    this.shippingOption = React.createRef("All");
    this.shippingOption.current = getFilterSelection("hub-selected-filter") || props.initialValue || "All";
  }

  reloadData = (fullReload = false) => {
    if (fullReload) this.setState({ muiTableKey: this.state.muiTableKey + 1 });
    this._tableRef.current.onQueryChange();
  };

  handleRoleFilterChange = (val) => {
    this.setState({ shippingOption: val });
    this.shippingOption.current = val;
    this.reloadData();
  };

  render() {
    const source = localStorage.getItem("source");
    const destination = localStorage.getItem("destination");
    return (
      <div className="bg-white rounded">
        <Backdrop open={this.state.working} />
        <HubProcessForm
          ref={this._HubProcessFormRef}
          reloadData={this.reloadData}
          shippingOption={this.shippingOption.current}
        />
        <Table
          title={"Shipped From the Vendors"}
          key={this.state.muiTableKey}
          options={{
            debounceInterval: 1000,
            searchAutoFocus: true,
            filtering: true,
          }}
          columns={CONFIG.TABLE_COLUMNS(this)}
          tableRef={this._tableRef}
          cellEditable={{
            onCellEditApproved: (
              newValue,
              oldValue,
              rowData,
              columnDef,
              source
            ) => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  handleUpdate(
                    rowData.raw_tracking,
                    oldValue,
                    newValue,
                    columnDef,
                    rowData.destination,
                    this
                  );
                  resolve();
                }, 500);
              });
            },
          }}
          actions={CONFIG.TABLE_ACTIONS(this, source)}
          data={(query) => fetchData(query, this, source)}
        />
      </div>
    );
  }
}
export default withSnackbar(Index);
