import React from "react";
import Table from "../../shared/tables/new_core_material_table";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import AddTrackingFrom from "./_shipping_form";
import AddToShipment from "./_add_to_shipment";
import PackingList from "./_packing_list";
import CONFIG from "./tableConfig";
import { fetchShipments, handleUpdate } from "./api";
import { getFilterSelection } from "../../shared/filter_selection";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      shipments: [],
      orderState: true,
      submitting: false,
      working: false,
      shippingMethods: [],
      activeCarriers: [],
    };
    this.shippingOption = React.createRef("All");
    this.shippingOption.current =
      getFilterSelection("hub-selected-filter") || props.initialValue || "All";
    this._tableRef = React.createRef();
    this._PackingList = React.createRef();
    this._AddToShipment = React.createRef();
    this._shippingFormRef = React.createRef();
  }

  reloadData = async () => {
    const source = localStorage.getItem("source");
    const destination = localStorage.getItem("destination");
    this.setState({ submitting: true });
    try {
      const data = await fetchShipments(this.shippingOption.current, source, destination);
      this.setState({
        shipments: data.shipments,
        shippingMethods: data.shipping_methods,
        activeCarriers: data.active_carriers,
      });
      window.breadcrumb.addBreadcrumb(data.breadcrumb);
    } catch (error) {
      this.props.enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      this.setState({ submitting: false });
    }
  };

  handleRoleFilterChange = (val) => {
    this.setState({ setSelectedRoles: val });
    this.shippingOption.current = val;
    this.reloadData();
  };

  componentDidMount() {
    this.reloadData();
  }

  render() {
    const source = localStorage.getItem("source");
    const destination = localStorage.getItem("destination");

    return (
      <>
        <Backdrop open={this.state.submitting} />
        <PackingList
          ref={this._PackingList}
          orderState={this.state.orderState}
          source={source}
          destination={destination}
        />
        <AddToShipment
          ref={this._AddToShipment}
          reloadData={this.reloadData}
          source={source}
          destination={destination}
        />
        <AddTrackingFrom
          ref={this._shippingFormRef}
          reloadData={this.reloadData}
          activeCarriers={this.state.activeCarriers}
          source={source}
          destination={destination}
        />
        <Table
          title="Ready to Ship"
          options={{
            toolbar: true,
            debounceInterval: 1000,
            selection: true,
          }}
          columns={CONFIG.TABLE_COLUMNS(this, source)}
          data={this.state.shipments}
          onSelectionChange={(rows) => {this.props.enqueueSnackbar('total weight selected ' + (rows.reduce((sum, row) => sum + parseInt(row.weight || 0, 10),0)), { variant: "info" })}}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  handleUpdate(newValue, rowData, columnDef, destination, this);
                  resolve();
                }, 500);
              }),
          }}
          actions={CONFIG.TABLE_ACTIONS(this, this.reloadData)}
        />
      </>
    );
  }
}

export default withSnackbar(Index);
