import React from "react";
import { Badge, FormControl, FormControlLabel, Radio, Select, MenuItem } from "@material-ui/core";
import pureIcons from "../../shared/pure_icons";
import Moment from "moment";
import {saveFilterSelection} from "../../shared/filter_selection";
import {handleReviewStatus} from "./api"

const TABLE_COLUMNS = (component) => [
  {
    title: "Tracking",
    field: "tracking",
    sorting: false,
    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 400,
    },
    filtering: false,
  },
  {
    title: "Order Number",
    field: "order_number",
    sorting: false,
    filtering: false,
    editable: "never",
    render: (rowData) => (
      <Badge
        badgeContent={rowData.new_customer ? "new" : null}
        color="secondary"
      >
        {rowData.order_number}
      </Badge>
    ),
  },
  {
    title: "Invoice",
    field: "invoice",
    filtering: false,
    sorting: false,
    editable: "never",
    cellStyle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 350,
      filtering: false,
    },
  },
  {
    title: "Vendor",
    field: "vendor",
    filtering: false,
    editable: "never",
    sorting: false,
  },
  {
    title: "Shipping Method",
    field: "shipping_method",
    editable: "never",
    sorting: false,
    filterComponent: () => (
      <FormControl component="fieldset" style={{ width: "90%" }}>
        <Select
          value={component.shippingOption.current}
          onChange={(e) => {
            component.handleRoleFilterChange(e.target.value),
            (component.shippingOption.current = e.target.value);
            saveFilterSelection("hub-selected-filter", e.target.value);
          }}
          >
          {component.state.shippingMethods?.map((item) => {
            return (
              <MenuItem value={item} style={{ padding: "0rem 1rem" }}>
                <FormControlLabel
                  value={item}
                  control={
                    <Radio checked={component.shippingOption.current == item} />
                  }
                  label={item}
                  />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    ),
    render: (rowData) => <span>{rowData?.shipping_option}</span>,
  },
  {
    title: "Filter",
    field: "group_id",
    sorting: false,
    lookup: {
      ISSUE: "ISSUE",
      DELIVERED: "DELIVERED",
      RETURN: "RETURN",
      EXPENSIVE: "EXPENSIVE",
      NO_TRACKING: "NO TRACKING",
      ___: "---",
    },
    emptyValue: "--",
  },
  {
    title: "ETA",
    field: "eta",
    type: "date",
    dateSetting: { format: "dd/MM/yyyy" },
    render: (rowData) => {
      return rowData.flag ? (
        <div style={{ color: "#FF0000" }}>
          {Moment(rowData.eta).format("DD/MM/YYYY")}
        </div>
      ) : (
        <div>{Moment(rowData.eta).format("DD/MM/YYYY")}</div>
      );
    },
    filtering: false,
  },
  {
    title: "Date",
    field: "created_at",
    filtering: false,
    editable: "never",
  },
  { title: "source", field: "source", hidden: true },
  { title: "market_source", field: "market_source", hidden: true },
];

const TABLE_ACTIONS = (component) => [
  (rowData) => ({
    icon: () => <pureIcons.MdPreview />,
    tooltip:
      rowData.is_reviewed === true ? (
        <span>Reviewed By: {rowData.reviewer}</span>
      ) : (
        <span>Mark as Reviewed</span>
      ),
    onClick: (event, rowData) => {
      window.confirm(
        `Are you sure you want to change status to be { REVIEWED } ?`
      ) && handleReviewStatus(rowData, component);
    },
    position: "row",
    disabled: rowData.is_reviewed === true,
  }),
  (rowData) => ({
    icon: () => <pureIcons.GiBoxUnpacking />,
    tooltip: <span>Ship {rowData.vendor}</span>,
    onClick: (event, rowData) => {
      component._HubProcessFormRef.current.handleOpen(
        rowData.raw_tracking,
        rowData.order_number,
        rowData.vendor,
        rowData.invoice,
        rowData.source,
        rowData.destination
      );
    },
    position: "row",
  }),
  {
    icon: () => <pureIcons.FiRefreshCw />,
    onClick: () => {
      component.reloadData();
    },
    isFreeAction: true,
    tooltip: "Refresh",
  },
];

const CONFIG = {
  TABLE_COLUMNS,
  TABLE_ACTIONS,
};

export default CONFIG;

