const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const fetchData = async (query, source, destination, component) => {
  let url = `/stages/purchased.json?destination=${destination}&shipping_option=${component.shippingOption.current}&source=${source}&`;
  url += "&per_page=" + query.pageSize;
  url += "&page=" + (query.page + 1);
  var filters;
  if (query.filters.length > 0) {
    filters = query.filters.map((col) => ({
      field: col.column.field,
      value: col.value,
    }));
    url += "&filters=" + JSON.stringify(filters);
  }
  if (query.orderBy) {
    url += "&orderBy=" + query.orderBy.field;
    url += "&orderDirection=" + query.orderDirection;
  }
  if (query.search) {
    url += "&search=" + query.search;
  }
  const response = await fetch(url);
  const result = await response.json();
  if (component.state.shippingMethods.length === 0) {
    component.setState({ shippingMethods: result.shipping_methods });
    window.breadcrumb.addBreadcrumb(result.breadcrumb);
  }
  return {
    data: result.invoices,
    page: result.page - 1,
    totalCount: result.total,
  };
};
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const handleReviewStatus = async (data, component) => {
  component.setState({ working: true });

  try {
    const response = await fetch(`/stages/${data.order_number}/new_stage_review`, {
      method: "PUT",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify({ invoice: data.invoice, stage: "purchased" }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    await response.json();
    component.props.enqueueSnackbar("Successfully updated!", {
      variant: "success",
    });
    component.reloadData();
  } catch (error) {
    component.props.enqueueSnackbar(error.message, {
      variant: "error",
    });
  } finally {
    component.setState({ working: false });
  }
};


export const handleReturnToPreviousStage = async (rowData, component) => {
  component.setState({ working: true });

  try {
    const response = await fetch(`/order_item_stages/return_to_previous_stage`, {
      method: "POST",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify({
        invoice: rowData.raw_invoice,
        destination: rowData.destination,
        order_number: rowData.order_number,
        vendor: rowData.vendor,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }
    await sleep(3000);
    component.reloadData();
    component.props.enqueueSnackbar("Successfully processed!", {
      variant: "success",
    });
  } catch (error) {
    component.props.enqueueSnackbar(error.message, {
      variant: "error",
    });
  } finally {
    component.setState({ working: false });
  }
};

export const handleUpdate = async (oldValue, newValue, columnDef, order_number, destination, component) => {
  if (oldValue === newValue || !newValue) return;

  const urls = {
    invoice: `/order_item_stages/update_stage_tracking.json?destination=${destination}`,
    eta: `/order_item_stages/update_stage.json?destination=${destination}`,
    total: `/order_item_stages/update_invoice_total.json?destination=${destination}`,
  };

  const dataMap = {
    invoice: {
      tracking: oldValue,
      new_tracking: newValue,
      stage: "purchased",
    },
    eta: {
      tracking: oldValue,
      eta: newValue,
      stage: "purchased",
    },
    total: {
      tracking: oldValue,
      total: newValue,
      order_number: order_number,
    },
  };

  const url = urls[columnDef.field];
  const data = dataMap[columnDef.field];

  if (!url || !data) return;

  component.setState({ working: true });

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "An error occurred");
    }

    await component.reloadData();
    component.props.enqueueSnackbar("Successfully processed!", { variant: "success" });
  } catch (error) {
    component.props.enqueueSnackbar(error.message, { variant: "error" });
  } finally {
    component.setState({ working: false });
  }
};


