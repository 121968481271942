import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import {
  Button,
  CircularProgress,
  Box,
  IconButton,
  Typography,
} from "@material-ui/core";
import Modal from "../../shared/modal/material_dialog";
import ItemCard from "./_item_card";
import ProductCardSkeleton from "../../shared/skeletons/product_card_skeleton";
import ShowOrder from "../_show";
import { withSnackbar } from "notistack";
import Backdrop from "../../shared/backdrop";
import CopyTextComponent from "../../shared/copy_clipboard";
import {getProducts, handleSubmit} from "./api"

const HubProcessForm = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [working, setWorking] = useState(false);
  const [received, setReceived] = useState([]);
  const [products, setProducts] = useState([]);
  const [tracking, setTracking] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [destination, setDestination] = useState(null);
  const [source, setSource] = useState(null);

  const _ShowOrderRef = useRef();

  useImperativeHandle(ref, () => ({
    handleOpen(tracking, orderNumber, vendor, invoice, source, destination) {
      setOpen(true);
      setTracking(tracking);
      setOrderNumber(orderNumber);
      setVendor(vendor);
      setInvoice(invoice);
      setDestination(destination);
      setErrors({});
      setSource(source);
      getProducts(tracking, destination, props.shippingOption, setWorking, handleClose, setProducts, setReceived, props);
    }
  }));

  const handleClose = () => {
    setOpen(false);
    setWorking(false);
    const searchBar = document.querySelector(`#pure_table_toolbar_id input`);
    if (searchBar) searchBar.focus();
  };

  const validate = () => {
    let temp = { ...errors };
    temp.serverErrors = "";

    if (received.length > 0) {
      temp.received = "";
    } else {
      temp.received = "One or more items must be selected!";
      props.enqueueSnackbar("One or more items must be selected!", {
        variant: "error",
      });
    }

    setErrors(temp);
    return Object.values(temp).every((x) => x === "");
  };

  const handleAfterSubmit = () => {
    if (
      received.length === products.length &&
      received.reduce((a, v) => a + v.qty, 0) === products.reduce((a, v) => a + v.qty, 0)
    ) {
      handleClose();
    } else {
      getProducts(tracking, destination, props.shippingOption, setWorking, handleClose, setProducts, setReceived, props);
    }
  };

  const setProductData = (data) => {
      if (data.selected) {
        setReceived((prev) => {
          const existing = prev.find((o) => o.order_item_stage_id === data.order_item_stage_id);
          if (existing) {
            return prev.map((o) =>
              o.order_item_stage_id === data.order_item_stage_id ? data : o
            );
          }
          return [...prev, data];
        });
      } else {
        setReceived((prev) =>
          prev.filter((o) => o.order_item_stage_id !== data.order_item_stage_id)
        );

        if (data.deleted) {
          setProducts((prev) => {
            const updated = prev.filter((o) => o.order_item_stage_id !== data.order_item_stage_id);
            if (updated.length === 0) {
              props.reloadData(true);
              handleClose();
            }
            return updated;
          });
        }
      }
    };

  const body = products.map((p) => (
    <ItemCard
      product={p}
      callBack={setProductData}
      key={p.order_item_stage_id}
      reloadData={props.reloadData}
      destination={destination}
    />
  ));

  const summary = (
    <div className="container">
      <div className="row ms-4">
        <div className="col">
          <Box pt={2} mt={1}>
            <Typography variant="button" color="textSecondary">
              Selected: {received.length}/{products.length}
            </Typography>
          </Box>
        </div>
      </div>
    </div>
  );

  const actions = working ? (
    <CircularProgress />
  ) : (
    <>
      <Button
        variant="contained"
        onClick={(e) => handleSubmit(e, handleAfterSubmit, props, validate, setWorking, data, handleClose)}
      >
        Create issue
      </Button>
      <div style={{flex: '1 0 0'}} />
      <Button
        variant="contained"
        style={{ backgroundColor: "#f6b67f", color: "#FFFFFF" }}
        onClick={(e) => handleSubmit(e, handleAfterSubmit, props, validate, setWorking, data, handleClose)}
      >
        Submit
      </Button>
    </>
  );

  const data = {
    received,
    tracking,
    order_number: orderNumber,
    destination,
    source,
    invoice,
    exception: false
  };

  const orderTitle = (
    <>
      <Button
        style={{ fontSize: "inherit", color: "#f6b67f", fontWeight: "bold" }}
        onClick={() => _ShowOrderRef.current.handleOpen(orderNumber, destination)}
      >
        {orderNumber}
      </Button>
      <IconButton
        onClick={() => navigator.clipboard.writeText(orderNumber)}
        aria-label="copy"
      >
        <CopyTextComponent textValue={orderNumber} />
      </IconButton>
      {`(${vendor} ${tracking}) ${invoice}`}
    </>
  );

  return (
    <div>
      <ShowOrder ref={_ShowOrderRef} />
      <Backdrop open={working} />
      <Modal
        disableBackdropClick
        isOpen={open}
        title={orderTitle}
        handleClose={handleClose}
        minHeight="40vh"
        maxHeight="85%"
        maxWidth="lg"
        summary={summary}
        actions={actions}
      >
        {working ? <ProductCardSkeleton /> : body}
      </Modal>
    </div>
  );
});

export default withSnackbar(HubProcessForm);
