const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const fetchData = async (query, component) => {

  const filters = query.filters.map((col) => ({
    field: col.column.field,
    value: col.value,
  }));

  const source = localStorage.getItem("source");
  let url = `/stages/vendor_shipped.json?source=${source}&shipping_option=${component.shippingOption.current}&`;
  url += "per_page=" + query.pageSize;
  url += "&page=" + (query.page + 1);
  if (filters.length > 0) url += "&filters=" + JSON.stringify(filters);
  if (query.orderBy) {
    url += "&orderBy=" + query.orderBy.field;
    url += "&orderDirection=" + query.orderDirection;
  }
  if (query.search) url += "&search=" + query.search;

  const response = await fetch(url);
  const result = await response.json();
  if (component.state.shippingMethods.length == 0) {
    component.setState({shippingMethods: result.shipping_methods});
    window.breadcrumb.addBreadcrumb(result.breadcrumb);
  }
  return {
    data: result.orders,
    page: result.page - 1,
    totalCount: result.total,
  };
};

export const handleReviewStatus = (data, component) => {
  fetch(`/stages/${data.order_number}/new_stage_review`, {
    method: "PUT",
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      tracking: data.tracking,
      stage: "shipped_from_vendor"
    })
  })
    .then(response => response.json())
    .then(response => {
      component.props.enqueueSnackbar("successfully updated!", {
        variant: "success",
      });
      component.reloadData();
    })
    .catch(error => {
      component.props.enqueueSnackbar(error.message, {
        variant: "error",
      });
      component.setState({ working: false });
    });
};

export const handleUpdate = async (tracking, oldValue, newValue, columnDef, destination, component) => {
  if (oldValue === newValue || newValue === "" || newValue === null) return;

  let url, data;
  switch (columnDef.field) {
    case "tracking":
      url = `/order_item_stages/update_stage_tracking.json?destination=${destination}`;
      data = {
        tracking: tracking,
        new_tracking: newValue,
        stage: "vendor_shipped",
      };
      break;
    case "eta":
      url = `/order_item_stages/update_stage.json?destination=${destination}`;
      data = { tracking: tracking, eta: newValue, stage: "vendor_shipped" };
      break;
    case "group_id":
      url = `/order_item_stages/update_stage.json?destination=${destination}`;
      data = {
        tracking: tracking,
        group_id: newValue,
        stage: "vendor_shipped",
      };
      break;
    default:
      return;
  }

  component.setState({ working: true });

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error);
    }

    await response.json(); // Optional if the response data is not needed
    component.setState({ working: false });
    component.reloadData();
    component.props.enqueueSnackbar("successfully processed!", {
      variant: "success",
    });
  } catch (error) {
    component.setState({ working: false });
    component.props.enqueueSnackbar(error.message, {
      variant: "error",
    });
  }
};

export const getProducts = (tracking, destination, shippingOption, setWorking, handleClose, setProducts, setReceived, props) => {
  setWorking(true);
  const url = `/order_item_stages/items_to_process_in_hub.json?tracking=${tracking}&destination=${destination}&shipping_option=${shippingOption}`;

  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      if (result.products.length === 0) {
        handleClose();
      } else {
        setProducts(result.products);
        setWorking(false);
        setReceived([]);
      }
    })
    .catch(() => {
      props.enqueueSnackbar(
        "Invalid tracking or items have been moved to the next stage!",
        { variant: "error" }
      );
      handleClose();
    });
};

export const handleSubmit = (event, handleAfterSubmit, props, validate, setWorking, data, handleClose) => {
  event.preventDefault();
  if (!validate()) return;

  setWorking(true);

  fetch(`/orders/hub_received`, {
    method: "POST",
    headers: DEFAULT_HEADERS,
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((error) => {
          throw new Error(error.error);
        });
      }
      return response.json();
    })
    .then(() => {
      setWorking(false);
      props.enqueueSnackbar("Successfully processed!", { variant: "success" });
      props.reloadData(true);
      handleAfterSubmit();
    })
    .catch((error) => {
      props.enqueueSnackbar(error.message, { variant: "error" });
      setWorking(false);
      handleClose();
    });
};


