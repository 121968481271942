import React from 'react';
import {forwardRef} from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {FiPackage, FiRefreshCw} from "react-icons/fi";
import {ImInfo} from "react-icons/im";
import {BsChatDots, BsBookshelf, BsPlusLg} from "react-icons/bs";
import {GiBoxUnpacking} from 'react-icons/gi'
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import {FaFileInvoice, FaShippingFast, FaExternalLinkAlt, FaFileInvoiceDollar} from "react-icons/fa";
import PrintIcon from '@material-ui/icons/Print';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {AiOutlinePlus, AiOutlineCheck, AiOutlineShoppingCart} from "react-icons/ai";
import {RxCross2} from "react-icons/rx";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {RiRefund2Fill, RiProhibitedLine} from "react-icons/ri";
import {MdPreview, MdOutlineSummarize, MdOutlinePendingActions, MdWarningAmber} from 'react-icons/md'
import {BiShow} from 'react-icons/bi'
import TrafficIcon from '@material-ui/icons/Traffic';
import {CgProfile} from "react-icons/cg";
import { TbTruckReturn } from "react-icons/tb";
import { GrMultiple } from "react-icons/gr";

const pureIcons =
  {
    RiProhibitedLine: forwardRef((props, ref) => <RiProhibitedLine {...props} ref={ref}/>),
    MdWarningAmber: forwardRef((props, ref) => <MdWarningAmber {...props} ref={ref}/>),
    GrMultiple: forwardRef((props, ref) => <GrMultiple {...props} ref={ref}/>),
    FaFileInvoiceDollar: forwardRef((props, ref) => <FaFileInvoiceDollar {...props} ref={ref}/>),
    BsBookshelf: forwardRef((props, ref) => <BsBookshelf {...props} ref={ref}/>),
    BsPlusLg: forwardRef((props, ref) => <BsPlusLg {...props} ref={ref}/>),
    TruckReturn: forwardRef((props, ref) => <TbTruckReturn {...props} ref={ref}/>),
    CgProfile: forwardRef((props, ref) => <CgProfile {...props} ref={ref}/>),
    BiShow: forwardRef((props, ref) => <BiShow {...props} ref={ref}/>),
    Traffic: forwardRef((props, ref) => <TrafficIcon {...props} ref={ref}/>),
    Summarize: forwardRef((props, ref) => <MdOutlineSummarize {...props} ref={ref}/>),
    PowerSettingsNewIcon: forwardRef((props, ref) => <PowerSettingsNewIcon {...props} ref={ref}/>),
    AiOutlinePlus: forwardRef((props, ref) => <AiOutlinePlus {...props} ref={ref}/>),
    AiOutlineCheck: forwardRef((props, ref) => <AiOutlineCheck {...props} ref={ref}/>),
    RxCross2: forwardRef((props, ref) => <RxCross2 {...props} ref={ref}/>),
    FaExternalLinkAlt: forwardRef((props, ref) => <FaExternalLinkAlt {...props} ref={ref}/>),
    FaShippingFast: forwardRef((props, ref) => <FaShippingFast {...props} ref={ref}/>),
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    NavigateBeforeIcon: forwardRef((props, ref) => <NavigateBeforeIcon {...props} ref={ref}/>),
    NavigateNextIcon: forwardRef((props, ref) => <NavigateNextIcon {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>),
    LockIcon: forwardRef((props, ref) => <LockIcon {...props} ref={ref}/>),
    LockOpenIcon: forwardRef((props, ref) => <LockOpenIcon {...props} ref={ref}/>),
    FiPackage: forwardRef((props, ref) => <FiPackage {...props} ref={ref}/>),
    FiRefreshCw: forwardRef((props, ref) => <FiRefreshCw {...props} ref={ref}/>),
    ImInfo: forwardRef((props, ref) => <ImInfo {...props} ref={ref}/>),
    BsChatDots: forwardRef((props, ref) => <BsChatDots {...props} ref={ref}/>),
    GiBoxUnpacking: forwardRef((props, ref) => <GiBoxUnpacking {...props} ref={ref}/>),
    CheckBoxIcon: forwardRef((props, ref) => <CheckBoxIcon {...props} ref={ref}/>),
    CheckBoxOutlineBlankIcon: forwardRef((props, ref) => <CheckBoxOutlineBlankIcon {...props} ref={ref}/>),
    FaFileInvoice: forwardRef((props, ref) => <FaFileInvoice {...props} ref={ref}/>),
    PrintIcon: forwardRef((props, ref) => <PrintIcon {...props} ref={ref}/>),
    ThumbUpIcon: forwardRef((props, ref) => <ThumbUpIcon {...props} ref={ref}/>),
    CheckCircleIcon: forwardRef((props, ref) => <CheckCircleIcon {...props} ref={ref}/>),
    BlockIcon: forwardRef((props, ref) => <BlockIcon {...props} ref={ref}/>),
    RiRefund2Fill: forwardRef((props, ref) => <RiRefund2Fill {...props} ref={ref}/>),
    RiRefund2Line: forwardRef((props, ref) => <RiRefund2Line {...props} ref={ref}/>),
    MdPreview: forwardRef((props, ref) => <MdPreview {...props} ref={ref}/>),
    AiOutlineShoppingCart: forwardRef((props, ref) => <AiOutlineShoppingCart {...props} ref={ref}/>),
    Pending: forwardRef((props, ref) => <MdOutlinePendingActions {...props} ref={ref} />),

  };

export default pureIcons;
