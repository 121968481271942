import React, { useRef } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
	Card,
	CardContent,
	FormControlLabel,
	CardMedia,
	Checkbox,
	Badge,
	IconButton,
	Typography, Link
} from '@material-ui/core';
import NoteCard from '../../notes/_note_card';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SpeedDials from './_speed_dials'
import LockIcon from '@material-ui/icons/Lock';
import Alert from "@material-ui/lab/Alert";
import Backdrop from "../../shared/backdrop";
import Barcode from '../../order_items/_barcode';
import NumericInput from 'react-numeric-input';
import StageIssues from '../../order_items/_stage_issues'

export default function MediaControlCard({product, callBack, reloadData, destination}) {

	const classes = useStyles();
	const [maxSelect, setMaxSelect] = React.useState(product.qty);
	const [state, setState] = React.useState({
		selected: false,
		qty: product.qty,
		processed: product.processed,
		remaining: product.qty,
		original_qty: product.original_qty,
		submitting: false,
		notes: product.notes,
		status: product.status,
		locked: product.locked,
		order_item_stage_id: product.order_item_stage_id,
		disabled: false,
	});

	const handleDeleteItem = () => {
		callBack({selected: false, order_item_id: product.order_item_id, order_item_stage_id: product.order_item_stage_id, deleted: true})
		setState({disabled: true})
	}

	const handleCallBackStageQty = (stageQty, updateQty=false) => {
		if(stageQty === 0)
		{ setState({...state, disabled: true, qty: stageQty, processed: state.original_qty - stageQty, remaining: stageQty})
			callBack({selected: false, order_item_id: product.order_item_id, deleted: true})
		} else {
			setMaxSelect(stageQty)
			if (updateQty) {
				setState({...state, qty: stageQty, original_qty: state.processed + stageQty, remaining: stageQty})
			} else {
				setState({...state, qty: stageQty, processed: state.original_qty - stageQty, remaining: stageQty})
			}
		}
	}

	const handleCheckBoxChange = (event) => {
		setState({...state, [event.target.name]: event.target.checked});
		callBack({
			selected: event.target.checked,
			qty: state.qty,
			order_item_id: product.order_item_id,
			price: product.price,
			shipping_to_hub: product.shipping_to_hub,
			order_item_stage_id: product.order_item_stage_id
		})
	}

	const handleQtyChange = (value) => {
		if (value > state.remaining || value <= 0 || value === null) return
		setState({...state, qty: value});
		callBack({
			selected: true,
			qty: value,
			order_item_id: product.order_item_id,
			price: product.price,
			shipping_to_hub: product.shipping_to_hub,
			order_item_stage_id: product.order_item_stage_id
		})
	};

	const handleNewNote = (new_note) => {
		var newNotes = [{user: 'You', gender: 'Male', message: new_note, date: ''}].concat(state.notes);
		setState({...state, notes: newNotes})
	}

	const handleLockStateChange = (new_state) => {
		setState({...state, locked: new_state})
		callBack({
			selected: false,
			qty: 0,
			order_item_id: product.order_item_id,
			order_item_stage_id: product.order_item_stage_id,
			price: product.price,
			shipping_to_hub: product.shipping_to_hub,
			locked: new_state
		})
	}

	const customization = product.customizations.map(c =>
	{if (c.value !== null && c.value !== '')
		return (<Typography variant='subtitle1' color='textSecondary' key={c.name}>
			{c.name}: <b>{c.value}</b>
		</Typography>)
	});

	const callBackStageIssue = (new_status) => {
		setState({...state, status: new_status})
	};

	return (
		<Card className={classes.root} hidden={state.disabled}>
			<Backdrop open={state.submitting}/>
			<CardMedia className={classes.cover}>
			<Badge badgeContent='DG' invisible={!product.dg} overlap="circular" color="error">
				<img src={product.image} alt={product.name} style={{height: '250px', width: '200px', objectFit: 'contain'}} />
			</Badge>
			</CardMedia>
			<div className={classes.details}>
				<CardContent className={classes.content}>
					<Typography component='h5' variant='h5'>
						<Link href={product.url} target="_blank">{product.name}</Link>
					</Typography>
					<div className='row'>
						<div className='col'>
							{product.prohibited && <Alert variant="filled" severity="error">This item could be Prohibited — please check it out!</Alert>}
							<Typography variant='subtitle1' color='textSecondary'>
								Original qty: <b>{state.original_qty}</b>
							</Typography>
							<Typography variant='subtitle1' color='textSecondary'>
								Processed: <b>{state.processed}</b>
							</Typography>
							<Typography variant='subtitle1' color='textSecondary'>
								Remaining qty: <b>{state.remaining}</b>
							</Typography>
							<Typography variant='subtitle1' color='textSecondary'>
								Price: <b>{product.price}</b>
							</Typography>
							{customization}
							{state.locked ?
								<LockIcon/>
								:
								<div className='row d-flex align-items-center'>
									<div className='col-1'>
										<FormControlLabel
											control={<Checkbox icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }}/>}
											                   checkedIcon={<CheckBoxIcon style={{fill: '#f6b67f', fontSize: 30}}/>}
											                   name='selected'
											                   defaultChecked={product.selected}
											                   onChange={handleCheckBoxChange}/>}
										/>
									</div>
									<div className='col-3'>
										<NumericInput min={1} max={maxSelect} value={state.qty} mobile size={4} onChange={handleQtyChange} name='qty' disabled={!state.selected}/>
									</div>
									<div className='col-2'>
										<IconButton aria-label="Barcode"><Barcode code={product.redeem_code} style={{ fontSize: 25 }}/></IconButton>
									</div>
								</div>
							}
							<StageIssues product={state} destination={destination} stage='vendor_shipped' callBackStageIssue={callBackStageIssue}/>
						</div>
						<div className='col'>
							<div>
								{state.notes && state.notes.length > 0 && <NoteCard notes={state.notes}/>}
							</div>
						</div>
					</div>
				</CardContent>
			</div>
			<div className='float-end'>
				<SpeedDials
					product={product}
					state={state}
					destination={destination}
					newNote={handleNewNote}
					handleDeleteItem={handleDeleteItem}
					callBackLockState = {handleLockStateChange}
					handleCallBackStageQty = {handleCallBackStageQty}
				/>
			</div>
		</Card>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		padding: 10,
		margin: 10,
		wordBreak: 'break-all'
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	content: {
		flex: '1 0 auto',
	},
	cover: {
		width: 250,
		height: 250,
		objectFit: 'contain'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
}));
