import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, TextField } from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog';
import { withSnackbar } from 'notistack';
import Backdrop from "../../shared/backdrop";
import $ from "jquery";

class ReturnForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errors: {},
      working: false,
      values: {},
      fields: [['address', 'Address'], ['city', 'City'], ['company', 'Company'], ['contact', 'Contact'], ['country', 'Country'], ['phone', 'Phone']]
    };
  }

  handleOpen = (data, tracking) => {
    let values = this.state.values;
    this.state.fields.forEach(f => values = { ...values, [`b_${f[0]}`]: '', [`d_${f[0]}`]: '' });
    this.setState({ open: true, data: data, values: values, tracking: tracking });
  };

  handleClose = () => {
    if (this.state.working) return;
    this.setState({ open: false, errors: {}, values: {} });
  };

  handleInputChange = (e) => {
    const { name, value, files } = e.target;
    const fieldValue = files ? files[0] : value; // Capture file if present
    this.setState({ values: { ...this.state.values, [name]: fieldValue } });
    this.validate({ [name]: fieldValue });
  }

  validate = (fieldValues = this.state.values) => {
    let temp = { ...this.state.errors };
    temp.serverErrors = '';
    if ('return_label' in fieldValues) {
      temp = {};
    }

    this.state.fields.forEach(f => {
      if (`b_${f[0]}` in fieldValues && !fieldValues.return_label) {
        temp[`b_${f[0]}`] = fieldValues[`b_${f[0]}`] ? '' : 'Required';
      }
      if (`d_${f[0]}` in fieldValues && !fieldValues.return_label) {
        temp[`d_${f[0]}`] = fieldValues[`d_${f[0]}`] ? '' : 'Required';
      }
    });
    this.setState({ errors: { ...temp } });

    if (fieldValues === this.state.values) {
      return Object.values(temp).every(x => x === '');
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.validate()) return;

    this.setState({ working: true });
    const { values, tracking, data } = this.state;
    const formData = new FormData();
    formData.append('action_name', 'return');
    formData.append('tracking', tracking);
    formData.append('source', this.props.source);
    formData.append('destination', this.props.destination);
    formData.append('shipping_option', this.props.shipping_option);
    formData.append('data', JSON.stringify(data));

    if (values.return_label instanceof File) {
      formData.append('file_name', 'return_label');
      formData.append('file', values.return_label);
    } else {
      formData.append('values', JSON.stringify(values));
    }

    try {
      const response = await $.ajax({
        url: `/order_item_stages/process_exceptions`,
        type: "POST",
        data: formData,
        dataType: "json",
        processData: false,
        contentType: false,
      });
      this.props.enqueueSnackbar("Successfully processed!", { variant: "success" });
      this.props.reloadData();
      this.setState({ working: false, open: false });
    } catch (error) {
      this.props.enqueueSnackbar(error.responseJSON.error, { variant: "error" });
      this.setState({ working: false });
    }
  }

  render() {
    const { classes } = this.props;
    const actions = (
      <Button
        variant='contained'
        style={{ backgroundColor: '#f6b67f', color: '#FFFFFF' }}
        type='submit'
        className={classes.button}
        onClick={this.handleSubmit}>
        {this.state.working ? 'Submitting' : 'Submit'}
      </Button>
    );

    const body = (
      <Card className={classes.root} style={{ minHeight: '30vh' }}>
        <div className="container pb-5">
          {!this.state.values.return_label && <>
            <div className="row">
              <div className="col"><h4>Base</h4></div>
              <div className="col"><h4>Destination</h4></div>
            </div>
            {this.state.fields.map(f =>
              <div className="row pt-2" key={f[0]}>
                <div className="col">
                  <TextField
                    className={classes.textField}
                    fullWidth
                    name={`b_${f[0]}`}
                    value={this.state.values?.[`b_${f[0]}`]}
                    label={f[1]}
                    {...(this.state.errors[`b_${f[0]}`] && {
                      error: true,
                      helperText: this.state.errors[`b_${f[0]}`]
                    })}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col">
                  <TextField
                    className={classes.textField}
                    name={`d_${f[0]}`}
                    fullWidth
                    value={this.state.values?.[`d_${f[0]}`]}
                    label={f[1]}
                    {...(this.state.errors[`d_${f[0]}`] && {
                      error: true,
                      helperText: this.state.errors[`d_${f[0]}`]
                    })}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            )}
          </>}
          <div className="row pt-5">
            <div className="mb-3">
              <label htmlFor="return_label" className="form-label">Return Label</label>
              <input
                className="form-control"
                type="file"
                name="return_label"
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>
      </Card>
    );

    return (
      <>
        <Backdrop open={this.state.working} />
        <Modal
          disableBackdropClick
          isOpen={this.state.open}
          title={'Return Form'}
          handleClose={this.handleClose}
          minHeight='40vh'
          maxHeight='70%'
          maxWidth='md'
          actions={actions}>
          {body}
        </Modal>
      </>
    );
  }
}

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export default withSnackbar(withStyles(useStyles)(ReturnForm));
